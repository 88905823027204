import React from "react"
import { Link } from "gatsby";
import { appConfig, SignUpState } from "../utils/environment";

import Head from "../components/head";
import Layout from "../components/layout";


import ConfirmationDone from "../../static/images/confirmation_done.svg";
import FaithTechDark from "../../static/images/faithtech_logo_dark.svg"
import GenericError from "../components/generic-error";

export default class Confirm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            confirmationComplete: false,
            processingRequest: false
        }
    }
    sendConfirm() {
        this.setState({ processingRequest: true });
        const search = this.props.location.search;
        const email = new URLSearchParams(search).get('email');
        const key = new URLSearchParams(search).get('key');

        if (!email || !key) {
            this.setState({ processingRequest: false, confirmationComplete: SignUpState.ERROR })
            return;
        }


        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                confirm: true,
                email,
                key
            })
        };

        fetch(appConfig.signUpUrl, requestOptions)
            .then(data => {
                if (data.ok) {
                    this.setState({ processingRequest: false, confirmationComplete: SignUpState.COMPLETE });
                  } else {
                    throw new Error(data);
                  }
                this.setState({ processingRequest: false, confirmationComplete: SignUpState.COMPLETE });
            }).catch(error => {
                this.setState({ processingRequest: false, confirmationComplete: SignUpState.ERROR })
            });
    }
    componentDidMount() {
        this.sendConfirm()
    }

    render() {
        return (
            <>
                <Head title={"Confirm"} />
                <Layout>
                    <div className="flex-col flex">
                        <div className="w-full mb-4 mx-2 md:mx-8 space-y-3 p-5 md:mb-0 ">
                            {!this.state.processingRequest && this.state.confirmationComplete === SignUpState.COMPLETE && (
                                <div className="justify-center items-center w-100 md:flex md:flex-col">

                                    <img
                                        src={ConfirmationDone}
                                        alt="A person standing next to a giant opened envelope"
                                        className="w-100 md:w-1/4"
                                    />
                                    <h2 className="w-100 md:w-1/3 text-black italic font-bold text-center py-4">
                                        Your Digital Sabbath Journey Has Begun!
                                    </h2>
                                    <p className="w-100 md:w-1/3 text-center mb-3 text-xl">
                                        You’ve officially signed up for the Digital Sabbath. You will receive your first reminder email before your Digital Sabbath day.
                                        We pray your Digital Sabbath is full of peace and rest.
                                    </p>
                                    <div className="w-100 flex justify-center mt-4">
                                        <a
                                            className="flex text-sm text-gray-500"
                                            href="https://faithtech.com/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <span className="mr-2 align-middle py-2">A project by</span>
                                            <img src={FaithTechDark} alt="FaithTech logo" className="w-24 align-middle" />
                                        </a>
                                    </div>
                                    <div className="my-4 py-3">
                                        <Link
                                            to={"/"}
                                            className="w-full md:w-40 btn-primary"
                                        >
                                            Back to Home
                                     </Link>
                                    </div>
                                </div>

                            )}
                            {!this.state.processingRequest && this.state.confirmationComplete === SignUpState.ERROR && (
                                <GenericError />)}
                            {this.state.processingRequest && (
                                <div>
                                    <h2 className="text-center">Processing your confirmation...</h2>
                                </div>
                            )}


                        </div>
                    </div>
                </Layout>
            </>
        );
    }
}
