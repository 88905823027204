import React from "react"
import { Link } from "gatsby";
import FaithTech from "../../static/images/faithtech_logo.svg"


export default function GenericError() {
    return (
        <div className="justify-center items-center w-100 md:flex md:flex-col">
            <h2>Whoops! Something went wrong</h2>
            <p>There was an error processing your request. </p>
            <p className="text-primary"><Link to={"/contact"}>Contact us for help</Link> </p>
            <div className="w-100 flex justify-center mt-4">
                <a
                    className="flex text-sm text-gray-500"
                    href="https://faithtech.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <span className="mr-2">A project by</span>
                    <img src={FaithTech} alt="FaithTech logo" className="w-24" />
                </a>
            </div>

        </div>)
}